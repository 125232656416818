<template>
  <div>
    <vx-card
      :title="`Projets en cours (${this.allProjects.list.length} devis
        / ${
          this.allProjects.list.filter((el) => el.status == 'projetNotOk')
            .length
        } devis sans suite
        / ${
          this.allProjects.list.filter((el) => el.status == 'notTerminate')
            .length
        } souscriptions non finalisées)
        `"
      class="mb-4"
    >
      <div slot="no-body">
        <div class="aab-vie-content">
          <div class="pt-2 pb-4">
            <div class="pt-2 pb-2">
              <vs-collapse>
                <vs-collapse-item class="aab-vie-collapse-item">
                  <div slot="header">
                    <div class="aab-vie-collapse-filter">Filtrage</div>
                  </div>
                  <div class="p-22">
                    <vs-row vs-w="12" vs-align="center">
                      <vs-col
                        class="pr-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <span class="pl-1 pr-1">Du</span>
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center"
                        >
                          <vs-input
                            v-model="startDate"
                            type="date"
                            class="
                              aab-vie-disable-input-date
                              inputx
                              w-full
                              mb-2
                              mt-2
                            "
                          />
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <span class="pl-1 pr-1">au </span>
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center"
                        >
                          <vs-input
                            type="date"
                            v-model="endDate"
                            :min="startDate"
                            class="
                              aab-vie-disable-input-date
                              inputx
                              w-full
                              mb-2
                              mt-2
                            "
                          />
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Etat</span>
                          <multiselect
                            v-model="projectStatus"
                            :options="allStatusOngoing"
                            :close-on-select="true"
                            placeholder="--Etat--"
                            label="label"
                            track-by="label"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Intermédiaire</span>
                          <multiselect
                            v-model="intermediary"
                            :options="intermediariesList"
                            :close-on-select="true"
                            placeholder="--Choisir un intermédiaire--"
                            label="fullname"
                            track-by="fullname"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Produit</span>
                          <multiselect
                            v-model="productProjet"
                            :options="allProduct"
                            :close-on-select="true"
                            placeholder="--Choisir un produit--"
                            label="label"
                            track-by="label"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>
                    </vs-row>
                    <vs-row vs-w="12" vs-align="center" vs-justify="center">
                      <vs-col
                        class="pt-4 pb-2"
                        vs-type="flex"
                        vs-align="center"
                        vs-justify="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <vs-button @click="resetFilter">
                          Réinitialiser
                        </vs-button>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>

            <div
              class="
                flex flex-wrap-reverse
                items-center
                data-list-btn-container
              "
            >
              <div>
                <div>
                  <vs-dropdown class="dd-actions cursor-pointer">
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div>
                <vs-dropdown class="dd-actions cursor-pointer ml-4">
                  <div
                    class="
                      p-3
                      shadow-drop
                      rounded-lg
                      d-theme-dark-bg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      w-32 w-full
                    "
                  >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="activePrompt = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PrinterIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Exporter</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="checkUserRole('ongoing_project').delete"
                      :disabled="projectSelected.length == 0"
                      @click="checkRemoveMultipleProject"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Supprimer la sélection</span>
                      </span>
                    </vs-dropdown-item>
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-dropdown-item :disabled="true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Supprimer la sélection</span>
                        </span>
                      </vs-dropdown-item>
                    </vx-tooltip>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
            <div class>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allProjects.list.length !== 0">
                  <vs-table
                    multiple
                    @dblSelection="ongoingProjectsSelectedDouble"
                    :data="allProjects.list"
                    v-model="projectSelected"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="intermediary">Intermédiaire</vs-th>
                      <vs-th sort-key="identity">Prospect</vs-th>
                      <vs-th sort-key="email">E-mail</vs-th>
                      <vs-th sort-key="phone">Téléphone</vs-th>
                      <vs-th sort-key="createdAt">Date</vs-th>
                      <vs-th sort-key="ref">Référence</vs-th>
                      <vs-th sort-key="status">Etat</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].broker">
                          <div v-if="data[indextr].broker">
                            {{ data[indextr].broker.person.firstname }}
                            {{ data[indextr].broker.person.lastname }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].quote">
                          {{ data[indextr].quote.firstname }}
                          {{ data[indextr].quote.lastname }}
                        </vs-td>
                        <vs-td :data="data[indextr].quote.email">{{
                          data[indextr].quote.email
                        }}</vs-td>
                        <vs-td :data="data[indextr].quote.phone">{{
                          data[indextr].quote.phone
                        }}</vs-td>
                        <vs-td :data="data[indextr].updatedAt">{{
                          dateElement({ birthday: data[indextr].updatedAt })
                            .birthdayInfo
                        }}</vs-td>
                        <vs-td :data="data[indextr].ref">{{
                          data[indextr].ref
                        }}</vs-td>
                        <vs-td :data="data[indextr].status">{{
                          displayStatus(data[indextr].status)
                        }}</vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <vs-dropdown class="dd-actions cursor-pointer">
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                              <vs-dropdown-menu class="aab-vie-dropdown">
                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('ongoing_project').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="
                                        checkViewFile(
                                          data[indextr].quote.filename
                                        )
                                      "
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="DownloadIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Télécharger </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="DownloadIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Télécharger </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('ongoing_project').update
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkRelaunch(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="RefreshCcwIcon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Relancer</span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="RefreshCcwIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Relancer</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('ongoing_project').update &&
                                      data[indextr].status != 'projetNotOk'
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkClose(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="LockIcon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3"
                                          >Classer sans suite</span
                                        >
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="LockIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Classer sans suite</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('ongoing_project').delete
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkRemove(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="Trash2Icon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Supprimer </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="Trash2Icon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Supprimer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                              </vs-dropdown-menu>
                            </vs-dropdown>

                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="
                                  loadingRemoveProject || loadingUpdateProject
                                "
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>

            <vs-popup
              class="holamundo"
              title="Relancer"
              :active.sync="popupRelaunch"
            >
              <div class="m-4">
                <aab-vie-subscribe-relaunch
                  v-on:onDisplayRelaunch="onDisplayRelaunch"
                  crrModuleName="OngoingProjet"
                  :item="formUpdated"
                ></aab-vie-subscribe-relaunch>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isResetFilter: false,
      productProjet: "",
      startDate: "",
      endDate: "",
      projectStatus: "",
      intermediariesList: [],
      intermediary: "",
      totalCountQuote: 0,
      totalCountNotOkQuote: 0,
      popupListFiles: false,
      filesList: [],
      global: {},
      formError: false,
      formUpdated: {},
      projectItem: {},
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingRemoveProject: false,
      loadingUpdateProject: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",
      popupRelaunch: false,
      // brokerTypeList: [
      //   { id: "company", label: "Entreprise" },
      //   { id: "particular", label: "Particulier" },
      // ],
      // profilList: [
      //   { id: "broker_inspector", label: "Inspecteur" },
      //   { id: "broker_leader", label: "Chef d'équipe" },
      //   { id: "broker_merchant", label: "Commercial" },
      //   { id: "broker_general_agent", label: "Agent général" },
      //   { id: "broker", label: "Courtier" },
      // ],
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Intermédiaire",
        "Prospect",
        "E-mail",
        "Téléphone",
        "Date",
        "Référence",
        "Statut",
      ],
      headerVal: [
        "index",
        "broker",
        "prospect",
        "email",
        "phone",
        "updatedAt",
        "ref",
        "status",
      ],
      activePrompt: false,
      //end export section
      projectSelected: [],
      crrIDs: [],
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
    this.getIntermediarySys();
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_project_pending
    ) {
      if (!this.checkUserRole("ongoing_project").all)
        this.$router.push({ name: "Home" });
    }
    this.limit = this.rowsTable[1];
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("suscribe", ["allProjects"]),
    ...mapGetters("general", [
      "rowsTable",
      "allStatusOngoing",
      "allProduct",
      "allPeriodicityOfPayment",
      "allCategorySocioPro",
      "familySituation",
    ]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("suscribe", [
      "applyGetAllProject",
      "applyChangeProjectState",
      "applyRemoveProject",
    ]),
    ...mapActions("users", ["applyGetIntermediariesSys"]),

    displayStatus(status) {
      console.log("stat", status);
      return this.allStatusOngoing.find((el) => el.id == status).label;
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    async getIntermediarySys() {
      let data = {
        limit: "Tout",
        skip: 0,
        type: [
          "broker_inspector",
          "broker_leader",
          "broker_merchant",
          "broker_general_agent",
          "broker",
        ],
      };
      this.loading = true;

      try {
        let intermediary = await this.applyGetIntermediariesSys(data);
        intermediary.list.forEach((el) => {
          this.intermediariesList.push({
            id: el.id,
            fullname: el.person.firstname + " " + el.person.lastname,
          });
        });

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    resetFilter() {
      this.isResetFilter = true;
      this.startDate = "";
      this.endDate = "";
      this.productProjet = "";
      this.intermediary = "";
      this.projectStatus = "";
      this.isResetFilter = false;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        isProjectPending: true,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        broker: this.intermediary.id ? this.intermediary.id : "",
        status: this.projectStatus.id ? this.projectStatus.id : "",
        productName: this.productProjet.id ? this.productProjet.id : "",
      });
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    checkViewFile(filename) {
      window.open(this.showFileData(filename), "_blank");
    },

    showFileData(data) {
      console.log("datatelech", data);
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            broker: el.broker
              ? el.broker.lastname + " " + el.broker.firstname
              : "",
            prospect: el.quote.lastname + " " + el.quote.firstname,
            email: el.quote.email,
            updatedAt: this.dateElement({ birthday: el.updatedAt })
              .birthdayInfo,
            ref: el.ref,
            phone: el.quote.phone,

            status: this.displayStatus(el.status),
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        this.allProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            prospect_sexe: el.quote.civility,
            prospect_lastname: el.quote.lastname,
            prospect_firstname: el.quote.firstname,
            prospect_birthday: this.formatDate(el.quote.birthday),
            prospect_phone: el.quote.phone,
            prospect_startDateContributions: this.formatDate(
              el.quote.startDateContributions
            ),
            prospect_email: el.quote.email,

            // profil_broker: this.profilList.find((type) => type.id == el.broker.type)
            //   ? this.profilList.find((type) => type.id == el.broker.type).label
            //   : "",
            // type_broker: this.brokerTypeList.find(
            //   (type) => type.id == el.personality
            // )
            //   ? this.brokerTypeList.find((type) => type.id == el.personality)
            //       .label
            //   : "",
            // userCode_broker: el.broker.userCode,
            // lastname_broker: el.broker.person.lastname,
            // firstname_broker: el.broker.person.firstname,
            // forSociety_broker: el.broker.person.forSociety,
            // addres_broker: el.broker.person.addres,
            // phone_broker: el.broker.person.phone,
            username_broker: `${el.broker.person.firstname} ${el.broker.person.lastname}`,
            // email_broker: el.broker.email,
            // logo_broker: this.showFileData(el.person.logo),

            // assure
            ...this.onGetAssured(el.subscription.assured),
            // subscriber
            ...this.onGetSubscriber(el.subscription.subscriber),
            // beneficiariesDeceased
            ...this.onGetBeneficiariesDeceased(
              el.subscription.beneficiariesDeceased
            ),
            // survey
            ...this.onGetSurvey(el.subscription.survey),
            ...this.onGetTarif(el.productName, el.quote),
            ref: el.ref,
            status: this.displayStatus(el.status),
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "status",
          "ref",

          // "profil_broker",
          // "type_broker",
          // "firstname_broker",
          // "lastname_broker",
          "username_broker",
          // "userCode_broker",
          // "email_broker",
          // "forSociety_broker",
          // "phone_broker",
          // "addres_broker",
          // "logo_broker",

          "prospect_sexe",
          "prospect_lastname",
          "prospect_firstname",
          "prospect_birthday",
          "prospect_phone",
          "prospect_email",
          "prospect_startDateContributions",

          "amountOfCapital",
          "periodicityOfPayment",
          "corverageDuration",
          "capitalDeathAllCauses",
          "absoluteDefinitiveDisabilityCapital",
          "doublingDeathBenefit",
          "partialPermanentDisability",
          "accumulationContributions",
          "ahr_amountOfCapital",
          "ahr_contributionPeriod",
          "ahr_corverageDuration",
          "ahr_startDateContributions",
          "ahr_endDateContributions",
          "ahr_numberOfContributions",
          "ahr_accumulationContributions",
          "ahr_accumulationOfContributions",

          "aae_amountOfCapital",
          "aae_deathOrAbsoluteDefinitiveDisability",
          "aae_periodicityOfPayment",
          "aae_corverageDuration",
          "aae_startDateContributions",
          "aae_endDateContributions",
          "aae_numberOfContributions",
          "aae_accumulationContributions",
          "aae_accumulationOfContributions",
          

          "subscriber_clientNumber",
          "subscriber_sexe",
          "subscriber_lastname",
          "subscriber_firstname",
          "subscriber_email",
          "subscriber_birthday",
          "subscriber_phone",
          "subscriber_commune",
          "subscriber_adress",
          "subscriber_categorySocioProfessional",
          "subscriber_familySituation",
          "subscriber_profession",
          "subscriber_identity",

          "assured_sexe",
          "assured_lastname",
          "assured_firstname",
          "assured_email",
          "assured_birthday",
          "assured_phone",
          "assured_commune",
          "assured_adress",
          "assured_categorySocioProfessional",
          "assured_familySituation",
          "assured_profession",
          "assured_identity",
          "BeneficiariesDeceased_name",
          "survey",
        ];
        // // tarif
        // "amountOfCapital", "corverageDuration","amountOfCapital", "amountOfCapital",
        // "doublingDeathBenefit","partialPermanentDisability",
        // assure
        //tarif
        // // ABQ
        // "Montant du capital", "Durée de couverture", "Capital Décès Toutes Causes",
        // "Capital Invalidité Absolue et Définitive", "Doublement du capital décès",
        // "Invalidité Partielle et Permanente",
        // // AHR
        // "Montant du capital à terme", "Périodicité des cotisations", "Durée des cotisations (en année)","Date de début des cotisations","Date de fin des cotisations","Nombre de cotisations","Cumul des cotisations mensuelles",
        // //AAE
        // "Rente annuelle à servir sur 5 ans","Décès ou Invalidité Absolue et Définitive","Périodicité des cotisations",
        // "Durée des cotisations","Date de début des cotisations","Date de fin des cotisations", "Nombre de cotisations", "Cumul des cotisations",
        //Souscripteur
        let headerTitleTmp = [
          "Etat",
          "Référence",

          // "Profil courtier",
          // "Type courtier",
          // "Prénom(s) courtier",
          // "Nom courtier",
          "Informations intermédiaire",
          // "Code apporteur courtier",
          // "Email courtier",
          // "Société courtier",
          // "Téléphone courtier",
          // "Adresse courtier",
          // "Logo courtier",

          "Civilité prospect",
          "Nom prospect",
          "Prénoms prospect",
          "Date de naissance prospect",
          "Téléphone prospect",
          "Email prospect",
          "Date d’effet souhaitée prospect",

          "Montant du capital",
          "Périodicité des cotisations",
          "Durée de couverture",
          "Capital Décès Toutes Causes",
          "Capital Invalidité Absolue et Définitive",
          "Doublement du capital décès",
          "Invalidité Partielle et Permanente",
          "Cotisation",

          "Montant du capital à terme",
          "Périodicité des cotisations",
          "Durée des cotisations (en année)",
          "Date de début des cotisations",
          "Date de fin des cotisations",
          "Nombre de cotisations",
          "Cotisation",
          "Cumul des cotisations",

          "Rente annuelle à servir sur 5 ans",
          "Décès ou Invalidité Absolue et Définitive",
          "Périodicité des cotisations",
          "Durée des cotisations",
          "Date de début des cotisations",
          "Date de fin des cotisations",
          "Nombre de cotisations",
          "Cotisation",
          "Cumul des cotisations",

          "Numéro client",
          "Civilité souscripteur",
          "Nom souscripteur",
          "Prénoms souscripteur",
          "Email souscripteur",
          "Date de naissance souscripteur",
          "Téléphone souscripteur",
          "Commune de naissance souscripteur",
          "Adresse de résidence souscripteur",
          "Catégorie socioprofessionnelle souscripteur",
          "Situation familiale souscripteur",
          "Profession souscripteur",
          "Pièce d’identité souscripteur",

          "Civilité assuré",
          "Nom assuré",
          "Prénoms assuré",
          "Email assuré",
          "Date de naissance assuré",
          "Téléphone assuré",
          "Commune de naissance assuré",
          "Adresse de résidence assuré",
          "Catégorie socioprofessionnelle assuré",
          "Situation familiale assuré",
          "Profession assuré",
          "Pièce d’identité assuré",

          "Clause bénéficiaire",
          "Questionnaire médical",
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    displayProfil(item) {
      return this.profilList.find((profil) => profil.id == item).label;
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    onGetAssured(data) {
      if (data) {
        return {
          assured_sexe: data.civility,
          assured_lastname: data.lastname,
          assured_firstname: data.firstname,
          assured_email: data.email,
          assured_birthday: this.formatDate(data.birthday),
          assured_phone: data.phone,
          assured_commune: data.commune,
          assured_adress: data.adress,
          assured_categorySocioProfessional: this.allCategorySocioPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocioPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          assured_familySituation: this.familySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.familySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          assured_profession: data.profession,
          assured_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          assured_sexe: "",
          assured_lastname: "",
          assured_firstname: "",
          assured_email: "",
          assured_birthday: "",
          assured_phone: "",
          assured_commune: "",
          assured_adress: "",
          assured_categorySocioProfessional: "",
          assured_familySituation: "",
          assured_profession: "",
          assured_identity: "",
        };
      }
    },
    onGetSubscriber(data) {
      console.log("data", data);

      if (data) {
        return {
          subscriber_sexe: data.civility,
          subscriber_clientNumber: data.clientNumber,
          subscriber_lastname: data.lastname,
          subscriber_firstname: data.firstname,
          subscriber_email: data.email,
          subscriber_birthday: this.formatDate(data.birthday),

          subscriber_phone: data.phone,
          subscriber_commune: data.commune,
          subscriber_adress: data.adress,
          subscriber_categorySocioProfessional: this.allCategorySocioPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocioPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          subscriber_familySituation: this.familySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.familySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          subscriber_profession: data.profession,
          subscriber_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          subscriber_sexe: "",
          subscriber_lastname: "",
          subscriber_firstname: "",
          subscriber_email: "",
          subscriber_birthday: "",
          subscriber_phone: "",
          subscriber_commune: "",
          subscriber_adress: "",
          subscriber_categorySocioProfessional: "",
          subscriber_familySituation: "",
          subscriber_profession: "",
          subscriber_identity: "",
        };
      }
    },
    onGetBeneficiariesDeceased(data) {
      if (data) {
        return {
          BeneficiariesDeceased_name:
            data.name == "" && data.value == ""
              ? `${data.customForm.lastname}, ${
                  data.customForm.firstname
                }, ${this.formatDate(data.customForm.birthday)}`
              : data.name == "myPartner"
              ? " Mon conjoint, à défaut mes enfants nés et à naître, à défaut mes ayants droit"
              : `${data.value}`,
        };
      } else {
        return {
          BeneficiariesDeceased_name: "",
        };
      }
    },
    onGetSurvey(data) {
      if (data) {
        let surveyData = [];
        data.forEach((res) => {
          if (res.response.text) {
            surveyData.push(
              `${res.question}: ${res.response.text.value} ${res.response.text.placeholder} `
            );
          } else {
            surveyData.push(
              `${res.question}: ${res.response.quickResponse ? "OUI" : "NON"}`
            );
          }
        });
        return { survey: surveyData.join("; ") };
      } else {
        return { survey: "" };
      }
    },
    onGetTarif(productName, data) {
      console.log(productName, data, "(productName Avoir, data) ");
      if (productName == "ABQ" || productName == "APF") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );                    

          return {
            periodicityOfPayment:"Unique",
            amountOfCapital: propositionChoose.amountOfCapital
              ? parseInt(propositionChoose.amountOfCapital)
              : "",
            capitalDeathAllCauses: propositionChoose.capitalDeathAllCauses
              ? parseInt(propositionChoose.capitalDeathAllCauses)
              : "",
            accumulationContributions:
              propositionChoose.accumulationContributions
                ? parseInt(propositionChoose.accumulationContributions)
                : "",
            absoluteDefinitiveDisabilityCapital:
              propositionChoose.absoluteDefinitiveDisabilityCapital
                ? parseInt(
                    propositionChoose.absoluteDefinitiveDisabilityCapital
                  )
                : "",
            corverageDuration: propositionChoose.corverageDuration
              ? parseInt(propositionChoose.corverageDuration)
              : "",
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            amountOfCapital: "",
            periodicityOfPayment: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
          };
        }
      } else if (productName == "AAF") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );                    

          return {
            periodicityOfPayment:"Annuelle",
            amountOfCapital: propositionChoose.amountOfCapital
              ? parseInt(propositionChoose.amountOfCapital)
              : "",
            capitalDeathAllCauses: propositionChoose.capitalDeathAllCauses
              ? parseInt(propositionChoose.capitalDeathAllCauses)
              : "",
            accumulationContributions:
              propositionChoose.accumulationContributions
                ? parseInt(propositionChoose.accumulationContributions)
                : "",
            absoluteDefinitiveDisabilityCapital:
              propositionChoose.absoluteDefinitiveDisabilityCapital
                ? parseInt(
                    propositionChoose.absoluteDefinitiveDisabilityCapital
                  )
                : "",
            corverageDuration: propositionChoose.corverageDuration
              ? parseInt(propositionChoose.corverageDuration)
              : "",
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            amountOfCapital: "",
            periodicityOfPayment: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
          };
        }
      } else if (productName == "AHR") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );

          return {
            ahr_amountOfCapital: propositionChoose.amountOfCapital
              ? parseInt(propositionChoose.amountOfCapital)
              : "",
            ahr_contributionPeriod: "Mensuelle",
            ahr_corverageDuration: propositionChoose.corverageDuration
              ? parseInt(propositionChoose.corverageDuration)
              : "",
            ahr_startDateContributions: this.formatDate(
              data.startDateContributions
            ),
            ahr_endDateContributions: this.formatDate(
              propositionChoose.endDateContributions
            ),
            ahr_numberOfContributions: propositionChoose.numberOfContributions
              ? parseInt(propositionChoose.numberOfContributions)
              : "",
            ahr_accumulationOfContributions:
              propositionChoose.accumulationOfContributions
                ? parseInt(propositionChoose.accumulationOfContributions)
                : "",
            ahr_accumulationContributions:
              propositionChoose.accumulationContributions
                ? parseInt(propositionChoose.accumulationContributions)
                : "",
          };
        } else {
          return {
            ahr_amountOfCapital: "",
            ahr_contributionPeriod: "",
            ahr_corverageDuration: "",
            ahr_startDateContributions: "",
            ahr_endDateContributions: "",
            ahr_numberOfContributions: "",
            ahr_accumulationOfContributions: "",
            ahr_accumulationContributions: "",
          };
        }
      } else if (productName == "AAE") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );

          return {
            aae_amountOfCapital: propositionChoose.amountOfCapital
              ? parseInt(propositionChoose.amountOfCapital)
              : "",
            aae_deathOrAbsoluteDefinitiveDisability:
              propositionChoose.deathOrAbsoluteDefinitiveDisability
                ? "Oui"
                : "Non",
            aae_periodicityOfPayment: this.allPeriodicityOfPayment.find(
              (el) => el.id == propositionChoose.periodicityOfPayment
            )
              ? this.allPeriodicityOfPayment.find(
                  (el) => el.id == propositionChoose.periodicityOfPayment
                ).label
              : "",

            aae_corverageDuration: propositionChoose.corverageDuration
              ? parseInt(propositionChoose.corverageDuration)
              : "",
            aae_startDateContributions: this.formatDate(
              data.startDateContributions
            ),
            aae_endDateContributions: this.formatDate(
              propositionChoose.endDateContributions
            ),
            aae_numberOfContributions: propositionChoose.numberOfContributions
              ? parseInt(propositionChoose.numberOfContributions)
              : "",
            aae_accumulationOfContributions:
              propositionChoose.accumulationOfContributions
                ? parseInt(propositionChoose.accumulationOfContributions)
                : "",
            aae_accumulationContributions:
              propositionChoose.accumulationContributions
                ? parseInt(propositionChoose.accumulationContributions)
                : "",
          };
        } else {
          return {
            aae_amountOfCapital: "",
            aae_periodicityOfPayment: "",
            aae_deathOrAbsoluteDefinitiveDisability: "",
            aae_corverageDuration: "",
            aae_startDateContributions: "",
            aae_endDateContributions: "",
            aae_numberOfContributions: "",
            aae_accumulationOfContributions: "",
            aae_accumulationContributions: "",
          };
        }
      }
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    async fetchApplyGetAllProject(data) {
      this.loading = true;
      try {
        await this.applyGetAllProject(data);
        this.total = this.allProjects.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;
        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkClose(item) {
      this.crrID = item.id;
      this.projectItem = { ...item };

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment fermer ce projet en cours ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.closeProject,
      });
    },

    async closeProject() {
      this.loadingUpdateProject = true;

      let data = {
        ...this.projectItem,
        projectId: this.crrID,
        status: "projetNotOk",
      };
      console.log("stat:", data.status);
      try {
        await this.applyChangeProjectState(data);
        this.acceptAlert(
          mixin.methods.message("ongoing_project", "update").success
        );
        this.formUpdated = {};
        this.loadingUpdateProject = false;
      } catch (err) {
        this.loadingUpdateProject = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("ongoing_project", "update").error
                  );
                  break;
              }
            });
          }
        }
      }
    },

    async fetchApplyUpdateProject() {
      this.loadingUpdateProject = true;

      let data = {};
      let Ids = [];
      console.log(this.formUpdated);
      this.formUpdated.intermediaries.forEach((el) => {
        Ids.push(el.id);
      });
      data = {
        userId: this.crrID,
        ...this.formUpdated,
        logo: this.fileData.data ? this.fileData.data[0].uploadedName : "",
        type: this.formUpdated.type.id,
        intermediaries: Ids,
      };

      console.log(data);

      try {
        await this.applyPutOngoingProject(data);

        this.acceptAlert(
          "success",
          "Mise à jour",
          "Intermédiaire a été mise à jour"
        );
        this.formUpdated = {
          email: "",
          firstname: "",
          lastname: "",
          type: "",
          intermediaries: [],
        };
        this.loadingUpdateProject = false;
      } catch (err) {
        this.loadingUpdateProject = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkRemoveMultipleProject() {
      this.projectSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les projets en cours sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleProject,
      });
    },

    async fetchRemoveMultipleProject() {
      let data = {
        ids: this.crrIDs,
      };

      this.loading = true;
      try {
        await this.applyRemoveProject(data);
        this.loading = false;
        this.acceptAlert(
          mixin.methods.message("ongoing_project", "multipleDeletion").success
        );
        this.crrIDs = [];
        this.projectSelected = [];
      } catch (err) {
        this.loading = false;
        this.crrIDs = [];
        this.projectSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("ongoing_project", "multipleDeletion")
                      .error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    checkRemove(project) {
      console.log("projet:", project);
      this.crrID = project.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ce projet  ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveProject,
      });
    },
    async fetchApplyRemoveProject() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveProject = true;
      try {
        await this.applyRemoveProject(data);
        this.loadingRemoveProject = false;
        this.acceptAlert(
          mixin.methods.message("ongoing_project", "delete").success
        );
        this.woIsAction = "";
        this.projectSelected = [];
      } catch (err) {
        this.loadingRemoveProject = false;
        this.woIsAction = "";
        this.projectSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Utilisateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("ongoing_project", "delete").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRelaunch(item) {
      this.formUpdated = { ...item };
      this.popupRelaunch = true;
    },
    onDisplayRelaunch(isClose) {
      this.popupRelaunch = isClose;
    },

    ongoingProjectsSelectedDouble(item) {
      if (this.checkUserRole("ongoing_project").show) {
        this.checkViewFile(item.quote.filename);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
  },
  watch: {
    projectStatus() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          isProjectPending: true,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          productName:
            this.productProjet && this.productProjet.id
              ? this.productProjet.id
              : "",
        });
      }
    },

    intermediary() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          isProjectPending: true,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          productName:
            this.productProjet && this.productProjet.id
              ? this.productProjet.id
              : "",
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          isProjectPending: true,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          productName:
            this.productProjet && this.productProjet.id
              ? this.productProjet.id
              : "",
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          isProjectPending: true,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          productName:
            this.productProjet && this.productProjet.id
              ? this.productProjet.id
              : "",
        });
      }
    },
    productProjet() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          isProjectPending: true,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          productName:
            this.productProjet && this.productProjet.id
              ? this.productProjet.id
              : "",
        });
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;
      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        isProjectPending: true,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        broker:
          this.intermediary && this.intermediary.id ? this.intermediary.id : "",
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : "",
        productName:
          this.productProjet && this.productProjet.id
            ? this.productProjet.id
            : "",
      });
    },
    limit() {
      this.skip = 0;
      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        isProjectPending: true,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        broker:
          this.intermediary && this.intermediary.id ? this.intermediary.id : "",
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : "",
        productName:
          this.productProjet && this.productProjet.id
            ? this.productProjet.id
            : "",
      });
    },
  },
  components: {
    ClipLoader,
    Multiselect,
  },
};
</script>

<style lang="scss">
.aab-vie-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.aab-vie-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
</style>
